import { z } from "zod";
import { useState } from "react";
import { Form, useFormHook } from "@/components/FormComps";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import Icon from "./ui/Icon";
import { sendGTMEvent } from "@next/third-parties/google";

export default function LoginBox() {
  const { locale } = useRouter();

  const validationErrors = {
    en: {
      email: {
        required: "Email is required",
        invalid: "Please enter a valid email",
      },
      password: {
        required: "Password is required",
        invalid: "Please type in your password",
      },
    },
    fa: {
      email: {
        required: "لطفا این قسمت را خالی نگذارید",
        invalid: "این قسمت نادرست است",
      },
      password: {
        required: "لطفا این قسمت را خالی نگذارید",
        invalid: "این قسمت نادرست است",
      },
    },
  };

  const schema = z.object({
    email: z
      .string({ required_error: validationErrors[locale!].email.required })
      .email({ message: validationErrors[locale!].email.invalid }),
    password: z
      .string({ required_error: validationErrors[locale!].password.required })
      .min(1, { message: validationErrors[locale!].password.invalid }),
  });

  const supabase = useSupabaseClient();
  const queryClient = useQueryClient();

  const onSubmit = async ({ email, password }) => {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) throw error;
    toast.success("You are Logged in.");
    queryClient.invalidateQueries({ queryKey: ["userMe"] });
  };

  const form = useFormHook({ schema, onSubmit });
  const [isPassVisible, setIsPassVisible] = useState<boolean>(false);

  const handleGoogleSignIn = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
    });

    if (error) {
      console.error("Error during sign-in:", error.message);
    } else {
      console.log("Sign-in successful:", data);
    }
  };

  return (
    <>
      <Form form={form} className="" hasSubmitButton={false}>
        <Form.Input name="email" label={`${locale === "en" ? "Email" : "ایمیل"}`} required dir="ltr" />

        <Form.Input
          name="password"
          type={isPassVisible ? "text" : "password"}
          required
          label={locale === "en" ? "Password" : "رمز عبور"}
          suffix={
            <button className="px-1em" type="button" onClick={() => setIsPassVisible((s) => !s)}>
              <span className="sr-only">Show Password</span>
              {!isPassVisible && <Icon name="bf-i-ph-eye" subdued={false} />}
              {isPassVisible && <Icon name="bf-i-ph-eye-closed" subdued={false} />}
            </button>
          }
          dir="ltr"
        />
        <Form.ServerErrorMessage />
        <Form.SubmitButton
          onClick={() => sendGTMEvent({ event: "login-pdp-modal-button" })}
          className="w-full login-button"
        >
          {locale === "en" ? "Login" : "ورود"}
        </Form.SubmitButton>
      </Form>
      <div className="flex items-center my-4">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="mx-2 text-gray-500">{locale === "fa" ? "یا" : "or"}</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>
      <button
        onClick={() => {
          sendGTMEvent({ event: "login-google-pdp-modal-button" });
          handleGoogleSignIn();
        }}
        className="mt-4 w-full flex items-center justify-center border border-gray-500 rounded-lg p-2 bg-white text-gray-900 hover:shadow-md hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 disabled:bg-gray-100 disabled:opacity-50"
      >
        <div className="flex items-center">
          <div className={`flex-shrink-0 ${locale === "fa" ? "ml-3" : "mr-3"}`}>
            <svg
              className="h-5 w-5"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              ></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              ></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              ></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              ></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </div>
          <span className="font-medium">{locale === "fa" ? "ورود با گوگل" : "Sign in with Google"}</span>
        </div>
      </button>
    </>
  );
}
