import useGetUserMe from "@/hooks/useGetUserMe";
import LoginBox from "@/components/LoginBox";
import Space from "@/components/ui/Space";
import Link from "next/link";
import { useRouter } from "next/router";
import { En, Fa } from "@/components/ui/multilang";
import { NextSeo } from "next-seo";

export async function getServerSideProps({ locale }) {
  return {
    props: {
      locale,
      title: locale === "fa" ? "ورود | درسون" : "Login | Darsoon",
      description:
        locale === "fa"
          ? "کلاس خصوصی آنلاین از موسیقی و ریاضی گرفته تا زبان فرانسوی. ویژه فارسی زبانان کانادا، آمریکا، استرالیا و اروپا. با بیش از ۲۰۰ معلم حرفه ای."
          : "Online private tutoring from Music and Math to French. Specifically for Farsi-speaking individuals in Canada, the US, Australia, and Europe. With over 200 professional tutors.",
    },
  };
}

export default function LoginPage({ title, description, locale }) {
  const { asPath } = useRouter();
  const baseUrl = "https://darsoon.com";
  const canonicalUrl = `${baseUrl}${locale === "fa" ? "" : "/en"}${asPath}`;

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={canonicalUrl}
        noindex={true}
        additionalLinkTags={[
          {
            rel: "alternate",
            href: `${baseUrl}${asPath}`,
            hrefLang: "fa",
          },
          {
            rel: "alternate",
            href: `${baseUrl}/en${asPath}`,
            hrefLang: "en",
          },
          {
            rel: "alternate",
            href: `${baseUrl}${asPath}`,
            hrefLang: "x-default",
          },
        ]}
      />

      <div className="max-w-50rem mx-auto max-w-md gap-6 grid items-center">
        <div>
          <h1 className="H3 !mb-8">{locale === "en" ? "Login" : "ورود"}</h1>
          <PageContent />
        </div>
      </div>
    </>
  );
}

const PageContent = () => {
  const { locale } = useRouter();
  const userMeQ = useGetUserMe();

  if (userMeQ?.isLoading) return <>Loading ...</>;
  if (!userMeQ?.data)
    return (
      <>
        <LoginBox />
        <Space size="h-6" />
        <div>
          <p className="fs-sm text-center font-semibold">
            {locale === "en" ? "Have you taken a class with Darsoon before? " : "قبلاً با درسون کلاس داشته‌اید؟ "}
            <Link className="underline" href="/request-reset-password">
              {locale === "en" ? "Click to get your password." : "برای گرفتن رمز خود کلیک کنید."}
            </Link>
          </p>
          <p className="fs-sm text-center font-semibold">
            {locale === "en" ? "No Account? " : "کاربر جدید؟ "}
            <Link className="underline" href="/signup">
              {locale === "en" ? "Sign Up here." : "اینجا حساب کاربری ایجاد کنید."}
            </Link>
          </p>
        </div>
      </>
    );

  return (
    <div>
      <En>
        <p>You are now logged into your account.</p>
        <p>
          Now you can go to the <Link href="/subjects">subjects page</Link> and select the tutor you want. Then, on this
          tutor&apos;s page, request an introductory session or, if you have already had an introductory session, make
          the payment to continue your classes.
        </p>
      </En>
      <Fa>
        <p>شما اکنون به حساب کاربری خود وارد شده‌اید.</p>
        <p>
          حالا می‌توانید به <Link href="/subjects">صفحه‌ی کلاس‌ها</Link> بروید و معلم مورد نظر خود را انتخاب کنید. سپس
          در صفحه‌ی این معلم درخواست جلسه معرفی بدهید یا اگر قبلا جلسه‌ معرفی داشته‌اید برای ادامه‌ کلاس‌هایتان پرداخت
          لازم را انجام دهید.
        </p>
      </Fa>
    </div>
  );
};
